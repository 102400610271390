import React, { useState, useEffect, useRef } from 'react';
import '../../styles/custome.css';
import { formatPhoneNumber } from "../../utils"
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import LayoutNew from "../../components/LayoutNew";
import Loading from "../../components/Loading"
import { lambdaUrl } from "../../config"
import { Simulate } from "react-dom/test-utils"
import WaitingListAddressComponent from "../new-pages/WaitingListAddressComponent";


const FibreWaitingListPage = () => {

  interface FormData {
    name: string;
    email: string;
    phone: string;
    address: string;
  }

  const [formData, setFormData] = useState<FormData>({
    "name": '',
    "email": '',
    "phone": '',
    "address": ''
  });

  const clearFormData = () => {
    formData.name = '';
    formData.email = '';
    formData.phone = '';
    formData.address = '';
  }

  const address = useRef<any>(null);
  const [phoneError, setPhoneError]: any = useState("");
  const [currError, setCurrError]: any = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const getAddress = () => {
    if (address.current) {
      let shippingAddress = address.current.getFormData().shipping_address;
      if (shippingAddress.ProvinceCode !== '') {
        formData.address = shippingAddress.Line1 + ", " + shippingAddress.City + ", " + shippingAddress.ProvinceCode + ", " + shippingAddress.PostalCode;
      }
    }
  };

  useEffect(() => {
    // Check local storage to maintain state across refreshes
    const submissionStatus = sessionStorage.getItem('fibreWaitingListFormSubmitted');
    if (submissionStatus === 'true') {
      setIsSubmitted(true);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let pNumber=e.target.value.replace(/\D/g, "")
    setFormData({ ...formData, phone: formatPhoneNumber(pNumber) })
    let phoneValid = pNumber.match(/^(\+1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/);
    if (!phoneValid) {
      phoneValid = pNumber.match(/^(\+?1 ?)?\(?([0-9]{1})\)?[-. ]?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/);
    }
    if (!phoneValid) {
      setPhoneError('Please enter a valid phone number');
    } else {
      setPhoneError('');
      setCurrError('');
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {

    // Set formData.address before passing it to the waiting list lambda
    getAddress();

    event.preventDefault();
    setIsLoading(true);
    if (phoneError !== "") {
      setIsLoading(false);
      setCurrError(phoneError);
      return;
    }
    try {
      const payload = { ...formData };
      clearFormData()
      const response = await fetch(`${lambdaUrl}enrol-fibre-waiting-list`, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      setIsLoading(false);
      if (!response.ok) {
        setCurrError('Please try again later.');
      } else {
        setCurrError('');
        setIsSubmitted(true);
        sessionStorage.setItem('fibreWaitingListFormSubmitted', 'true');
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false);
      setCurrError('Please try again later.');
    }
  };

  if (isSubmitted) {
    return (
      <LayoutNew>
        <Helmet>
          <title>Form submitted – Purple Cow Internet 💜🐄</title>
        </Helmet>
        <div className="main-sponsorships-container">
          <div className="contact-form-container">
            <div className="text-center logo-center">
              <Link to="/">
                <StaticImage
                  className="w-24"
                  layout="constrained"
                  src="../content/cow.png"
                  alt="Purple Cow Internet 💜🐄"
                />
              </Link>
            </div>
            <h2>Form Submitted!</h2>
            <h4>Thank you for your submission. We will be in touch shortly.</h4>
          </div>
        </div>
      </LayoutNew>
    )
  }

  if (isLoading) {
    return <div
      style={{
        display: 'flex',
        justifyContent: 'center',  // Center horizontally
        alignItems: 'center',      // Center vertically
        height: '100vh'            // Full viewport height
      }}
    >
      <Helmet>
        <title>Form submitted – Purple Cow Internet 💜🐄</title>
      </Helmet>
      <Loading></Loading>
    </div>
  }

  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Fibre Waiting List – Purple Cow Internet 💜🐄</title>
      </Helmet>
      <div className="main-sponsorships-container">
        <div className="contact-form-container">
          <div className="text-center logo-center"></div>

          <section className="tv-section4">
            <div className="container mx-auto px-4 text-center">
              <h2 className="h2 text-secondary text-center ">
                Something really fun is coming
              </h2>
              <p className="p2">
                Over the past two and a half years, we’ve been working on
                something special—something we believe will change the internet
                game in Nova Scotia forever. Some of you might already have a
                hint, as you may have seen our trucks in your community. We’ll
                be unveiling what we’ve been up to at the beginning of 2025.
                Just to build a little excitement: this new development will be
                a big win for Nova Scotian's and will allow Purple Cow to
                continue fulfilling our mission—creating a magical customer
                experience while driving down internet costs. Stay tuned!
              </p>
            </div>
          </section>
          <h2 className="h2 text-secondary text-center ">
            Join the list for updates!
          </h2>
          <form id={"fibre-waiting-list-form"} onSubmit={handleSubmit}>
            <div className="container mx-auto px-4">
              <div>
                <label>First Name *: </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData?.name}
                  required
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Email *: </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData?.email}
                  required
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Phone *: </label>
                <span className="error-field">{phoneError}</span>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Cellular/Mobile Number"
                  value={formData?.phone}
                  required
                  maxLength={16}
                  pattern="^(\+1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})"
                  onChange={handlePhoneNumberChange}
                />
              </div>

              <div className="relative">
                <label>Address *: </label>
                <div className="home-top-feild">
                  <input
                    type="text"
                    className="rounded-lg  focus:z-10 input1"
                    name="line11"
                    required
                    placeholder="Service Address"
                    id="home_new_address"
                    onBlur={(e) => {
                      let address = document.getElementById("home_new_address")
                      if (address !== null) {
                        setFormData({ ...formData, address: address.value });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <WaitingListAddressComponent ref={address} />
            <div className="steps-bottom-row m-auto address-step-form-bottom">
              <div className="button-box step-btn-right">
                <span className="error-field">{currError}</span>

                <button
                  className="btn bg-primary-700 text-white"
                  id="submit_address"
                >
                  {" "}
                  Submit Form{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutNew>
  )
}

// Render the webpage
export default FibreWaitingListPage
